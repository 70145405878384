import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Archives = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  let editions = data.allMarkdownRemark.distinct

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  editions.sort((a, b) => {
    const [monthA, yearA] = a.split(" ")
    const [monthB, yearB] = b.split(" ")
    return yearB - yearA || months.indexOf(monthB) - months.indexOf(monthA)
  })

  return (
    <Layout title={siteTitle} page="archives">
      <SEO
        title="Archives"
        keywords={[
          `blog`,
          `samratchana`,
          `mahajyothi`,
          `baba`,
          `magazine`,
          `siva shankar baba`,
        ]}
      />
      <header className="page-head">
        <h4 className="page-head-title">Archives</h4>
      </header>
      <article
        className="post-content page-template no-image"
        style={{ paddingTop: "2vw" }}
      >
        <div className="post-content-body-archives">
          <ul>
            {editions.map(e => (
              <li key={e} type="square">
                <Link to={`/${e.split(" ")[1]}/${e.split(" ")[0]}`}>{e}</Link>
              </li>
            ))}
          </ul>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark {
      distinct(field: frontmatter___edition)
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Archives location={props.location} data={data} {...props} />
    )}
  />
)
